import { createApp } from "vue";

import DashboardLayout from "./components/DashboardLayout.vue";
import EmptyLayout from "./components/EmptyLayout.vue";
import "./assets/tailwind.css?v=1.0.7";
import axios from "axios";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initializeApp } from "firebase/app";
import "firebase/auth";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

const appFirebase = initializeApp(firebaseConfig);
const app = createApp(App).use(store).use(appFirebase).use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

if (store.state.user._id) {
  if (!store.state.user.updated) {
    window.location.reload();
    store.state.user.updated = true;
    axios.post(`${process.env.VUE_APP_API}/auth/checkUpdate`, {
      _id: store.state.user._id,
      version: process.env.VUE_APP_VERSION,
    });
  }
}

app.component("default-layout", DashboardLayout);
app.component("empty-layout", EmptyLayout);

app.use(router).mount("#app");
