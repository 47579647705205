<template>
  <div>
    <div class="flex flex-col space-y-2 items-center w-fit">
      <div>
        <input
          type="number"
          v-model="tmp_quantity"
          @change="$emit('updateQuantity', tmp_quantity)"
          class="w-8 border border-gray-200 rounded"
        />
      </div>
      <div>
        <button
          class="material-icons-round text-bluevk"
          @click="
            handlerArticle(
              {
                article: article,
                stockData: [],
              },
              tmp_quantity
            )
          "
          :disabled="loadingAddCart"
        >
          add_shopping_cart
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { prepareArticleClient } from "@/hooks/searchEngine/article.ts";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: "componentPanier",
  data() {
    return {
      tmp_quantity: 0,
      loadingAddCart: false,
    };
  },
  props: {
    article: { type: Object, default: () => ({}) },
    quantity: Number,
    savedSearch: {
      type: String,
      default: "",
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapGetters(["user", "plateform", "actionArticle", "tabClient"]),
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
    ]),
    async handlerArticle(structure, qtyToPanier) {
      this.loadingAddCart = true;
      var existArticle = false;
      // await MisePanier(this.savedSearch, structure.article.Ref_fournisseur);
      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) +
            parseInt(qtyToPanier);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        let plateform_mere = this.user.plateform;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        var dataTab = await prepareArticleClient(
          structure,
          plateform_mere,
          this.user.proprietaire,
          this.tabClient.Group.Name,
          parseInt(qtyToPanier)
        );

        await this.addactionArticle(dataTab);
        await this.addConsigne(dataTab);
        if (structure.article.fraisdeport) {
          if (structure.article.fraisdeport > 0) {
            var countArticle = 0;
            for (const [index, actArticle] of this.actionArticle.entries()) {
              if (
                actArticle.Code_marque == structure.article.Code_marque &&
                actArticle.Ref_fournisseur !=
                  "Transport " + structure.article.Code_marque
              ) {
                countArticle++;
              }
            }
            if (countArticle % 5 == 0) {
              this.addactionArticle({
                _id: "fraisdeport" + structure.article.Code_marque,
                Ref_fournisseur: "Transport " + structure.article.Code_marque,
                Prix_euro: structure.article.fraisdeport,
                Prix_vente: structure.article.fraisdeport,
                PrixFournisseurType: "prixnet",
                disabled: true,
                quantity: 1,
                remise: 0,
                prixtotal: structure.article.fraisdeport,
                prixfinal: structure.article.fraisdeport,
                Code_marque: structure.article.Code_marque,
                Description:
                  "Transport dépannage fournisseur " +
                  structure.article.Code_marque,
                commentaire: "",
              });
            }
          }
        }
      }

      await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/savedSearchInCard`,
        {
          id: this.savedSearch,
          Ref_fournisseur: structure.article.Ref_fournisseur,
        }
      );
      this.loadingAddCart = false;
      this.success();
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        // if (this.user.proprietaire.dsvCheck == false) {
        this.addactionArticle({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
        // }
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
      } else {
        this.adddatanonconsigne(dataConsigne);
      }
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.tmp_quantity = this.quantity;
  },
};
</script>
<style></style>
