<template>
  <div class="relative w-full h-full md:rounded-lg overflow-hidden h-70 px-5">
    <div class="flex flex-row mt-0 bg-red-600 md:rounded-t-lg justify-center">
      <router-link
        to="/PromoMosaique"
        class="flex text-white text-1xl font-medium flex-row items-baseline p-1 cursor-pointer"
      >
        <h4 class="mr-1 hover:scale-110 duration-300">
          Découvrez <span class="underline font-medium">ici</span> nos
          promotions en cours
        </h4>
      </router-link>
    </div>

    <div
      class="w-full"
      @mouseover="stopAutoplay()"
      @mouseleave="startAutoplay()"
    >
      <!-- Carousel wrapper -->
      <div v-for="(promo, index) in promos" :key="index">
        <div
          v-if="index == currentIndex"
          :class="{
            'translate-x-0': currentIndex === index,
            'translate-x-full': currentIndex !== index,
          }"
          class="flex items-center w-full h-3/4 slide-left rounded-lg bg-white object-cover"
        >
          <div
            v-for="(promoOne, indexP) in promo"
            :key="indexP"
            @click="$router.push('/PromoMosaique')"
          >
            <div class="h-96">
              <img
                :src="promoOne.url"
                class="object-cover"
                :alt="`Image ${indexP + 1}`"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Slider indicators -->
      <div class="">
        <div
          class="absolute flex space-x-3 -translate-x-1/2 bottom-2 md:bottom-10 left-1/2"
        >
          <button
            v-for="i in promos.length"
            :key="i"
            :id="'carousel-indicator-' + i"
            :class="{
              'w-3 h-3 rounded-full': true,
              'bg-darkblue dark:bg-gray-800': currentIndex === i - 1,
              'bg-darkblue/50 dark:bg-gray-800/50 hover-bg-darkblue dark:hover-bg-gray-800':
                currentIndex !== i - 1,
            }"
            @click="goToSlide(i - 1)"
            :aria-current="currentIndex === i - 1"
            :aria-label="'Slide ' + i"
          ></button>
        </div>
      </div>

      <!-- Slider controls -->
      <button
        id="data-carousel-prev"
        type="button"
        class="absolute hover:bg-gray-100 hover:bg-opacity-60 border-l-md top-3 md:top-0 md:left-5 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        @click="prevSlide"
      >
        <span
          class="inline-flex items-center justify-center w-5 h-5 md:w-9 md:h-9 rounded-full white/30 bg-black/60 group-hover-bg-darkblue dark:group-hover-bg-gray-800/60 group-focus-ring-4 group-focus-ring-darkblue dark:group-focus-ring-gray-800/70 group-focus-outline-none bg-darkblue/50"
        >
          <svg
            class="w-2 h-2 md:w-4 md:h-4 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 12"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
          <span class="hidden">Previous</span>
        </span>
      </button>
      <button
        id="data-carousel-next"
        type="button"
        class="absolute hover:bg-gray-100 hover:bg-opacity-60 border-r-md top-3 md:top-0 right-0 md:right-5 flex items-center justify-center h-full px-4 cursor-pointer group focus-outline-none"
        @click="nextSlide"
      >
        <span
          class="inline-flex items-center justify-center w-5 h-5 md:w-9 md:h-9 rounded-full white/30 bg-black/60 group-hover-bg-darkblue dark:group-hover-bg-gray-800/60 group-focus-ring-4 group-focus-ring-white dark:group-focus-ring-gray-800/70 group-focus-outline-none"
        >
          <svg
            class="w-2 h-2 md:w-4 md:h-4 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 12"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span class="hidden">Next</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeUnmount, onMounted } from "vue";

export default {
  name: "MyCarousel",
  props: ["tabPromo"],
  data() {
    return {
      promos: [],
    };
  },
  setup(props) {
    const isCarouselPage = ref(false);

    const currentIndex = ref(0);
    const timer = ref(null);

    var promosPar3 = [];
    for (var i = 0; i < props.tabPromo.length; i += 3) {
      promosPar3.push(props.tabPromo.slice(i, i + 3));
    }
    const itemsCount = ref(promosPar3.length);

    // Méthodes
    const startAutoplay = () => {
      timer.value = setInterval(nextSlide, 2500);
    };

    const stopAutoplay = () => {
      clearInterval(timer.value);
      timer.value = null;
    };

    const nextSlide = () => {
      currentIndex.value = (currentIndex.value + 1) % itemsCount.value;
    };

    const prevSlide = () => {
      currentIndex.value =
        (currentIndex.value - 1 + itemsCount.value) % itemsCount.value;
    };

    const goToSlide = (index) => {
      currentIndex.value = index;
    };

    onMounted(() => {
      startAutoplay();
    });

    // Désactivez la classe spécifique lorsque le composant est démonté
    onBeforeUnmount(() => {
      stopAutoplay();
    });

    return {
      currentIndex,
      timer,
      itemsCount,
      startAutoplay,
      stopAutoplay,
      nextSlide,
      prevSlide,
      goToSlide,
      isCarouselPage,
    };
  },
  methods: {
    getSousTab() {
      var promosPar3 = [];

      for (var i = 0; i < this.tabPromo.length; i += 3) {
        promosPar3.push(this.tabPromo.slice(i, i + 3));
      }

      this.promos = promosPar3;
    },
  },
  beforeMount() {
    this.getSousTab();
  },
};
</script>

<style scoped>
.slide-left {
  transform: translateX(0);
}
.slide-right {
  transform: translateX(100%);
}
</style>
