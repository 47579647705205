<template>
  <div
    v-if="openImage && Images.length > 0 && Images[0].imageURL800"
    :class="`modal ${
      !openImage && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openImage = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; height: 60vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openImage = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div
          class="flex flex-row items-center space-x-2 h-[45vh] justify-center"
        >
          <div class="flex flex-col space-y-1 h-full overflow-y-auto">
            <div
              v-for="(img, indexImg) in Images"
              :key="indexImg"
              class="bg-orange-100 w-20 cursor-pointer"
            >
              <img
                :src="img.imageURL200"
                alt="image"
                class="w-full"
                @click="selectedImage = img.imageURL800"
              />
            </div>
          </div>
          <div class="w-3/4">
            <img :src="selectedImage" alt="image" class="mx-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row p-2 bg-white rounded shadow w-full h-fit">
    <div class="hidden md:flex my-auto bg-blue-100">
      <input
        type="checkbox"
        disabled
        v-if="compareTabLength > 2 && compareTabLength <= 3 && !checkbox_value"
        v-model="checkbox_value"
        @change="$emit('checkedArticle')"
      />
      <input
        type="checkbox"
        v-else
        v-model="checkbox_value"
        @change="$emit('checkedArticle')"
      />
    </div>
    <div class="hidden md:flex md:flex-col my-auto space-y-2 w-10">
      <span
        class="material-icons-round cursor-pointer text-blue-500"
        @click="
          searchRefFournisseurGen({
            Ref_fournisseur: Ref_fournisseur,
            genArt: genericArticleId,
          })
        "
      >
        sync
      </span>
    </div>
    <div class="w-2/12">
      <img
        v-if="Images.length > 0 && Images[0].imageURL200"
        @click="openImages()"
        :src="Images[0].imageURL200"
        alt="image"
        class="w-full max-h-full mx-auto w-16 h-16 md:w-24 md:h-24 lg:w-34 lg:h-34 cursor-pointer"
      />
      <img
        v-else
        src="@/assets/not-found.png"
        alt="image"
        class="w-full max-h-full mx-auto w-16 h-16 md:w-24 md:h-24 lg:w-34 lg:h-34"
      />
    </div>
    <div class="flex flex-col w-2/5 md:w-2/12 text-sm md:text-base">
      <div class="w-fit text-blue-500 cursor-pointer" @click="openImages()">
        {{ Ref_fournisseur }}
      </div>
      <div class="flex lg:hidden font-bold">{{ Code_marque }}</div>
      <div class="flex md:hidden">
        <!-- <stockvue
          class="my-auto"
          :Stock="tmp_stock"
          :Ref_fournisseur="Ref_fournisseur"
          :Code_marque="Code_marque"
        /> -->
      </div>
    </div>
    <div class="w-1/12 hidden lg:flex lg:flex-col">
      <div class="font-bold">{{ Code_marque }}</div>
      <div></div>
    </div>
    <div class="w-2/12 hidden lg:flex">{{ Description }}</div>
    <div class="w-2/12 lg:1/12 hidden md:flex md:flex-col md:space-y-2 mx-auto">
      <span class="w-full">{{ systemeFreinage }}</span>
      <span class="w-full">{{ coteAssemblage }}</span>
      <span class="w-full">{{ typeMontage }}</span>
    </div>
    <div class="w-3/12 lg:3/12 hidden md:flex md:flex-col md:space-y-2">
      <!-- <span v-if="Systeme.formattedValue">{{ Systeme.formattedValue }}</span>
      <span v-if="Location.formattedValue">{{ Location.formattedValue }}</span> -->
      <button
        class="flex flex-row items-center space-x-1 mx-auto text-sm text-white bg-blue-500 py-1 px-2 rounded shadow"
        @click="
          searchRefFournisseurGen({
            Ref_fournisseur: Ref_fournisseur,
            genArt: genericArticleId,
          })
        "
      >
        <span>Voir alternatif</span>
        <span class="material-icons-round"> search </span>
      </button>
    </div>
    <div class="w-3/12 lg:3/12 hidden md:flex md:flex-col md:space-y-2">
      <!-- <span v-if="Systeme.formattedValue">{{ Systeme.formattedValue }}</span>
      <span v-if="Location.formattedValue">{{ Location.formattedValue }}</span> -->
      <!-- <button
        class="flex flex-row items-center space-x-1 mx-auto text-sm text-white bg-blue-500 py-1 px-2 rounded shadow"
        @click="
          searchRefFournisseurGen({
            Ref_fournisseur: Ref_fournisseur,
          })
        "
      >
        <span>Demander un tarif ?</span>
        <span class="material-icons-round"> search </span>
      </button> -->
    </div>
  </div>
</template>
<script>
import stockvue from "@/components/Article/stock.vue";
import prixvue from "@/components/Article/prix.vue";
// import paniervue from "@/components/Article/panier.vue";
import { checkStock } from "@/hooks/stock/stock.ts";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "catalogueTD",
  data() {
    return {
      tmp_stock: {},
      checkbox_value: false,
      openImage: false,
      selectedImage: "",
      systemeFreinage: "",
      coteAssemblage: "",
      typeMontage: "",
    };
  },
  props: {
    article: { type: Object, default: () => ({}) },
    tdArticle: { type: Object, default: () => ({}) },
    Ref_fournisseur: String,
    Description: String,
    Code_marque: String,
    genericArticleId: Number,
    mfrId: Number,
    Images: Array,
    Location: Object,
    Systeme: Object,
    Stock: {
      type: Object,
      default: () => ({
        state: "En rupture",
        stock: 0,
        stockFournisseur: 0,
        adresse: "",
        min: 0,
        max: 0,
        filliale: [],
      }),
    },
    Prix: {
      type: Object,
      default: () => ({
        promo: false,
        prix_achat: 0.0,
        prix_brut: 0.0,
        prix_net: 0.0,
      }),
    },
    quantity: Number,
    compareTabLength: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // stockvue,
    // prixvue,
    // paniervue,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["searchRefFournisseurGen"]),
    async checkQuantity(n) {
      this.tmp_stock = await checkStock(
        this.user.proprietaire,
        this.article.Ref_fournisseur,
        this.article._id,
        this.article.Code_marque,
        n,
        this.user._id
      );
    },
    openImages() {
      this.openImage = true;
      this.selectedImage = this.Images[0].imageURL800;
    },
  },
  mounted() {
    this.tmp_stock = this.Stock;
    if (this.tdArticle != {} && this.tdArticle.articleCriteria) {
      for (const criteria of this.tdArticle.articleCriteria) {
        if (criteria.criteriaDescription === "Système de freinage") {
          this.systemeFreinage = criteria.formattedValue;
        }
        if (criteria.criteriaDescription === "Côté d'assemblage") {
          this.coteAssemblage = criteria.formattedValue;
        }
        if (criteria.criteriaDescription === "Type de montage") {
          this.typeMontage = criteria.formattedValue;
        }
      }
    }
  },
};
</script>
<style></style>
